<template>
	<div>
		<v-card class="pa-5">
			<v-container class="pa-0" v-if="currentStep == 1">
				<v-row>
					<v-col>
						<p
							class="text-h6 text-justify font-weight-bold"
							style="
								color: #3c4043;
								font: normal normal bold 18px/24px Nunito;
								letter-spacing: 0px;
							"
						>
							Let's get started
						</p>
						<p
							style="
								line-height: 1;
								font: normal normal normal 16px/22px Nunito;
								letter-spacing: 0.48px;
								color: #3c4043;
							"
						>
							Typically, as mentioned earlier, you can finish this
							in 15 to 30 minutes on average.
						</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" sm="12" md="12">
						<v-form ref="form1" @submit.prevent="submitnative">
							<v-btn v-show="false" type="submit">submit</v-btn>
							<v-text-field
								ref="email"
								label="Your Email Address"
								placeholder=""
								:rules="
									is_email_dirty
										? [
												rules.required,
												rules.email,
												rules.isValidEmail,
										  ]
										: [rules.required]
								"
								outlined
								v-model="formData.personal.email"
								hide-details="auto"
								class="mb-4"
								@blur="checkEmailAPI"
								><template v-slot:append>
									<v-fade-transition leave-absolute>
										<v-progress-circular
											v-if="isLoadingAPI"
											size="24"
											color="info"
											indeterminate
										></v-progress-circular>
										<v-icon
											color="#50C878"
											v-if="showCheckMarkEmail"
											>mdi-check</v-icon
										>
									</v-fade-transition>
								</template></v-text-field
							><v-text-field
								label="First Name"
								:rules="[rules.required, rules.counter]"
								outlined
								v-model="formData.personal.firstname"
								hide-details="auto"
								class="mb-4"
							></v-text-field>
							<v-text-field
								label="Last Name"
								:rules="[rules.required, rules.counter]"
								outlined
								v-model="formData.personal.surname"
								hide-details="auto"
								class="mb-4"
							></v-text-field>
							<Pager @vc="checkRequired" />
						</v-form>
						<p class="sidenote mt-6">
							Protecting Your Data: We prioritise the security of
							all information sent through DEBT ADVICE BOOSTER.
							Our website adheres to data protection standards to
							ensure your privacy is safeguarded. To find out more
							about J3 Debt and DEBT HELP BOOSTER, and how it
							protects your data, see our
							<a
								target="_blank"
								href="https://www.j3debt.co.uk/privacy-policy/"
								class="j3link"
								>Privacy Notice.</a
							>
						</p>
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 2">
				<v-row>
					<v-col>
						<p
							style="
								line-height: 1;
								font: normal normal normal 16px/22px Nunito;
								letter-spacing: 0.48px;
								color: #3c4043;
							"
							class="mt-0 mb-5"
						>
							Tell us a bit more about you:.
						</p>
						<v-form ref="form2" @submit.prevent="submitnative">
							<v-autocomplete
								outlined
								label="Title"
								:items="['Mr.', 'Ms.', 'Mrs.', 'Dr.']"
								:rules="[rules.required]"
								v-model="formData.personal.title"
								hide-details="auto"
								class="mb-4"
							></v-autocomplete>
							<v-text-field
								label="First Name"
								:rules="[rules.required, rules.counter]"
								outlined
								v-model="formData.personal.firstname"
								hide-details="auto"
								class="mb-4"
							></v-text-field>
							<v-text-field
								label="Middle Name"
								outlined
								v-model="formData.personal.middlename"
								hide-details="auto"
								class="mb-4"
							></v-text-field>
							<v-text-field
								label="Last Name"
								:rules="[rules.required, rules.counter]"
								outlined
								v-model="formData.personal.surname"
								hide-details="auto"
								class="mb-4"
							></v-text-field>

							<v-text-field
								ref="mobile"
								label="Mobile Number"
								:rules="[
									rules.required,
									rules.counter,
									rules.isValidphone,
								]"
								outlined
								v-model="formData.personal.mobile"
								hide-details="auto"
								class="mb-4"
								@blur="checkMobileAPI"
								><template v-slot:append>
									<v-fade-transition leave-absolute>
										<v-progress-circular
											v-if="isLoadingAPI"
											size="24"
											color="info"
											indeterminate
										></v-progress-circular>
										<v-icon
											v-if="showCheckMarkMobile"
											color="#50C878"
											>mdi-check</v-icon
										>
									</v-fade-transition>
								</template></v-text-field
							>

							<v-text-field
								v-model="formData.personal.date_of_birth"
								label="Date of Birth (DD/MM/YYYY)"
								:rules="[rules.isOverEighteen]"
								@input="formatDate"
								outlined
								mask="##-##-####"
								type="date"
							></v-text-field>
							<div
								style="
									text-align: right;
									width: 100%;
									font: normal normal normal 12px/16px Nunito;
									letter-spacing: 0.36px;
									color: #606368;
									position: relative;
								"
							>
								<span
									style="
										position: absolute;
										right: 0px;
										top: -20px;
									"
									>You’ll need to be over 18</span
								>
							</div>
							<AddressInput />
							<Pager @vc="checkRequired"
						/></v-form>
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 3">
				<v-row>
					<v-col>
						<InfoBox
							><template v-slot:heading
								>Identify your creditors. </template
							><template v-slot:content
								>To identify your creditors, carefully review
								your financial statements, bills, or mobile
								apps. Look for details on who you owe money to
								and the corresponding amounts.
							</template></InfoBox
						>
						<v-form ref="form3">
							<Creditors />
						</v-form>

						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 4">
				<v-row>
					<v-col
						><InfoBox
							><template v-slot:heading
								>Employment status </template
							><template v-slot:content
								>Help us understand you better. Share some
								details about your current situation.
							</template></InfoBox
						>

						<v-form ref="form4">
							<J3YesNo v-model="formData.employmentStatus.ccj"
								><template v-slot:heading
									>Do you have any CCJ’s? (County Court
									Judgement)
								</template>
							</J3YesNo>
							<div class="tenspacer"></div>
							<div class="tenspacer"></div>
							<v-autocomplete
								outlined
								label="Provide brief details of the reason for your current financial difficulties"
								:rules="[rules.required]"
								:items="[
									'Unemployment/redundancy',
									'Reduction in income',
									'Increased living costs',
									'Unexpected one-off expense',
									'Lifestyle changes',
									'Long term sickness',
									'Caring for family or friends',
									'Bereavement',
									'Pregnancy or childbirth',
									'Separation or divorce',
									'Overcommitment',
									'Taken debt out for someone else',
									'Addiction',
									'Gambling',
									'COVID-19',
									'I’d rather not say at this time',
									'Other',
								]"
								v-model="formData.employmentStatus.reason"
								hide-details="auto"
								class="mb-4"
							></v-autocomplete>

							<v-autocomplete
								outlined
								label="Employment status"
								:rules="[rules.required]"
								:items="[
									'Full time employment',
									'Part time employment',
									'Self employment',
									'Unemployed',
									'Studying (Full time)',
									'Studying (Part time)',
									'Retried',
									'Carer',
									'Illness or Disability',
									'Other',
								]"
								v-model="formData.employmentStatus.employment"
								hide-details="auto"
								class="mb-4"
							></v-autocomplete>

							<v-text-field
								v-show="
									formData.employmentStatus.employment !=
									`Retried`
								"
								label="Job Title"
								outlined
								v-model="formData.employmentStatus.jobtitle"
								hide-details="auto"
								class="mb-4"
							></v-text-field>

							<J3Currency
								label="Annual Income"
								v-model="formData.employmentStatus.income_main"
							/>

							<J3Currency
								label="Other Income"
								v-model="formData.employmentStatus.income_other"
							/>

							<p class="sidenote mt-3">
								Please detail how much do you receive from work,
								benefits, and any other sources, per annum.
							</p>

							<InfoBox
								><template v-slot:heading
									>Health & wellbeing </template
								><template v-slot:content
									>We provide personalised assistance for
									customers who feel they might need it,
									including additional options for managing
									debts. Sharing this information is
									voluntary, and you can update it anytime in
									your account.
								</template></InfoBox
							>

							<J3YesNo v-model="formData.healthWellbeing.mental"
								><template v-slot:heading
									>Are there any current physical or mental
									health conditions affecting your financial
									management?
								</template>
							</J3YesNo>

							<div class="tenspacer"></div>

							<v-autocomplete
								outlined
								label="Select any that apply to you"
								:items="[
									'Mental health conditions',
									'Physical health condition',
									'Suicidal thoughts',
									'Terminal illness',
									'A disability',
									'A recent bereavement',
									'Victim or survivor of domestic abuse',
									'Problem with alcohol',
									'Problem with drugs',
									'Problem with gambling',
									'I’d rather not say at this time',
								]"
								v-model="formData.healthWellbeing.mental_option"
								hide-details="auto"
								class="mb-4"
							></v-autocomplete>

							<J3YesNo
								v-model="formData.healthWellbeing.sharemental"
								><template v-slot:heading
									>Do we have your permission to share this
									information with your creditors?
								</template>
							</J3YesNo>
						</v-form>
						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 5">
				<v-row>
					<v-col
						><InfoBoxL
							><template v-slot:heading
								>We might have a solution for you. </template
							><template v-slot:content
								>To suggest the best debt solution for you, we
								will require additional information about your
								finances.
							</template></InfoBoxL
						></v-col
					>
				</v-row>
				<v-row>
					<v-col>
						<ThreeLine
							><template v-slot:heading
								>What you’ll need. </template
							><template v-slot:content
								><p>
									Introducing a fresh approach in
									collaboration with Experian to streamline
									the process of sharing your debt information
									online—an effortless, quick, and free
									solution.
								</p>

								<p class="sidenote mt-3">
									You can locate precise figures on your pay
									slips or by reviewing your bank statements
									to see the incoming payments.
								</p>
							</template></ThreeLine
						>
						<div class="tenspacer"></div>
						<ThreeLine
							><template v-slot:heading
								>Your expenditure. </template
							><template v-slot:content
								><p>
									This includes household expenses like rent
									or mortgage, bills, and weekly groceries.
								</p>

								<p class="sidenote mt-3">
									To gauge your spending, the most effective
									method is to review past bank statements. Be
									sure to consider one-time repairs and
									seasonal expenses like birthday gifts.
								</p>
							</template></ThreeLine
						>
					</v-col>
				</v-row>
				<v-row>
					<v-col> <Pager @vc="noCheckNext" /></v-col>
				</v-row>
			</v-container>

			<v-container class="pa-0" v-if="currentStep == 6">
				<v-row>
					<v-col
						><InfoBox
							><template v-slot:heading>Your home. </template
							><template v-slot:content
								>We need to know a bit more about your
								household, so we can help clearly identify your
								budget.
							</template></InfoBox
						></v-col
					>
				</v-row>
				<v-row>
					<v-col>
						<v-form ref="form6">
							<J3YesNo v-model="formData.home.ownproperty"
								><template v-slot:heading
									>Do you own any property?
								</template>
							</J3YesNo>
							<div class="tenspacer"></div>
							<v-autocomplete
								outlined
								label="Residential status?"
								:rules="[rules.required]"
								:items="[
									'Own',
									'Renting',
									'Living with Partner(s)',
									'Other',
								]"
								v-model="formData.home.residential_status"
								hide-details="auto"
								class="mb-4"
							></v-autocomplete>

							<v-autocomplete
								outlined
								label="What’s your marital status?"
								:items="['Single', 'Married']"
								v-model="formData.home.marital_status"
								hide-details="auto"
								class="mb-4"
							></v-autocomplete>

							<J3Currency
								label="Rent/Mortgage costs"
								v-model="formData.home.rent_mortgage_cost"
							/>

							<J3YesNo
								v-model="
									formData.home
										.in_arrears_with_your_rent_or_mortgage
								"
								><template v-slot:heading
									>Are you in arrears with your rent/mortgage?
									If so, how much?
								</template>
							</J3YesNo>

							<div class="tenspacer"></div>

							<J3Currency
								v-show="
									formData.home
										.in_arrears_with_your_rent_or_mortgage ==
									`yes`
								"
								label="How much do you owe?"
								v-model="formData.home.owe"
							/>

							<J3MultiToggle
								v-model="
									formData.home.ppl_depend_on_you_financially
								"
								><template v-slot:heading
									>How many people depend on you financially?
								</template>
							</J3MultiToggle>

							<p class="sidenote mt-3">
								This typically refers to a child, but could be
								anyone financially dependent on you, whether
								they reside in your household or not regardless
								of their age.
							</p>

							<J3YesNo v-model="formData.home.towardsavehicle"
								><template v-slot:heading
									>Do you own or pay for the use of a vehicle?
								</template>
							</J3YesNo>
							<div class="tenspacer"></div>
							<v-text-field
								label="How old is it?"
								v-if="formData.home.towardsavehicle == 'yes'"
								:rules="[rules.required]"
								outlined
								v-model="formData.home.vehicle_age"
								hide-details="auto"
								class="mb-4"
							></v-text-field>

							<J3Currency
								v-if="formData.home.towardsavehicle == `yes`"
								label="What is the value of the vehicle?"
								v-model="formData.home.vehicle_value"
							/>

							<v-autocomplete
								outlined
								label="How do you pay for the vehicle?"
								v-if="formData.home.towardsavehicle == 'yes'"
								:rules="[rules.required]"
								:items="[
									'Cash purchase',
									'Hire purchase',
									'Personal Contract Plan (PCP)',
									'Car lease / Personal Contract Hire (PCH)',
									'Unsecured loan - I don’t want to include this',
									'Unsecured loan - I want to include this',
									'It’s in another Name',
									'',
								]"
								v-model="formData.home.pay_for_vehicle"
								hide-details="auto"
								class="mb-4"
							></v-autocomplete>
						</v-form>

						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 7">
				<v-row>
					<v-col
						><InfoBox
							><template v-slot:heading>Your assets. </template
							><template v-slot:content
								>Assets include valuable possessions like a
								caravan, investments, or savings. We need this
								information for tailored solutions, though it
								won’t necessarily impact your assets.
							</template></InfoBox
						></v-col
					>
				</v-row>
				<v-row>
					<v-col>
						<v-form ref="form7">
							<J3YesNo v-model="formData.assets.over_1000"
								><template v-slot:heading
									>Do you have any assess over the value of
									£1,000
								</template>
							</J3YesNo>
							<div class="tenspacer"></div>
							<v-autocomplete
								outlined
								v-if="formData.assets.over_1000 == 'yes'"
								label="Type of asset."
								:items="['Item', 'Savings or Investment']"
								v-model="formData.assets.type_of_assets"
								hide-details="auto"
								class="mb-4"
							></v-autocomplete>

							<v-text-field
								label="What is it?… TV, Artwork, Laptop."
								v-if="formData.assets.over_1000 == 'yes'"
								:rules="[rules.required, rules.counter]"
								outlined
								v-model="formData.assets.assets_name"
								hide-details="auto"
								class="mb-4"
							></v-text-field>

							<v-text-field
								label="What is the value"
								v-if="formData.assets.over_1000 == 'yes'"
								prefix="£"
								:rules="[rules.required]"
								outlined
								v-model="formData.assets.assets_value"
								hide-details="auto"
								class="mb-4"
							></v-text-field>

							<v-text-field
								label="Is there an outstanding balance?"
								v-if="formData.assets.over_1000 == 'yes'"
								prefix="£"
								:rules="[rules.required]"
								outlined
								v-model="formData.assets.outstanding_balance"
								hide-details="auto"
								class="mb-4"
							></v-text-field>

							<p class="sidenote mt-3">
								If you bought an item using financing, use this
								section to inform us about any remaining
								balance.
							</p>

							<J3YesNo
								v-model="
									formData.assets
										.outstanding_balance_against_property
								"
								><template v-slot:heading
									>Is the outstanding balance secured against
									your property? If you are unsure, that’s ok,
									tell us anyway.
								</template>
							</J3YesNo>
							<div class="tenspacer"></div>
							<J3YesNo v-model="formData.assets.is_essential"
								><template v-slot:heading
									>Is the item essential?
								</template>
							</J3YesNo>
							<p class="sidenote mt-3">
								An essential item is something that is
								essential, for example, due to a disability, for
								your work, or for running your household, e.g.
								washing machine, oven, fridge/freezer.
							</p>
							<div class="tenspacer"></div>
						</v-form>
						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 8">
				<v-row>
					<v-col
						><InfoBox
							><template v-slot:heading
								>Next, your budget & income. </template
							><template v-slot:content
								>Creating a budget is the most effective way to
								regain control of your finances.
							</template></InfoBox
						></v-col
					>
				</v-row>
				<v-row>
					<v-col>
						<p
							class="text-h6 text-justify font-weight-bold"
							style="color: #50a1c5"
						>
							Your income.
						</p>
						<p style="line-height: 1.3">
							The following questions focus on your income,
							whether from employment, benefits, or support from
							family and friends. Ensure you provide details if
							you receive it regularly.
						</p>
						<v-form ref="form8">
							<J3YesNo v-model="formData.income.any_benefits"
								><template v-slot:heading
									>Do you receive any benefits?
								</template>
							</J3YesNo>

							<p class="sidenote mt-3">
								Things like income support, child benefits or
								disability living allowance.
							</p>
							<J3YesNo v-model="formData.income.is_pension"
								><template v-slot:heading
									>Do you receive a pension?
								</template>
							</J3YesNo>
							<div class="tenspacer"></div>
							<J3Monthly v-model="formData.income.income_aboard"
								><template v-slot:heading
									>Income from board or lodgings
								</template>
							</J3Monthly>

							<J3Monthly v-model="formData.income.from_friends"
								><template v-slot:heading
									>Financial support from family or friends
								</template>
							</J3Monthly>

							<J3Monthly v-model="formData.income.student_loan"
								><template v-slot:heading
									>Student loans or grants
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.income.any_other_income"
								><template v-slot:heading
									>Any other income
								</template>
							</J3Monthly>
						</v-form>
						<div class="tenspacer"></div>
						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 9">
				<v-row>
					<v-col
						><InfoBox
							><template v-slot:heading
								>And, of course, your outgoings. </template
							><template v-slot:content
								>This is your regular spending, rent, bills,
								groceries, etc. Providing details of your
								outgoings is crucial for personalised advice.
							</template></InfoBox
						></v-col
					>
				</v-row>
				<v-row>
					<v-col>
						<p
							class="text-h6 text-justify font-weight-bold"
							style="color: #50a1c5"
						>
							Your outgoings.
						</p>
						<p style="line-height: 1.3" class="mb-6">
							Now, let’s look at your outgoings. Please provide
							the amounts and frequencies for each item listed, so
							that we can provide tailored advice that truly suits
							you - accuracy in these figures is crucial.
						</p>
						<v-form ref="form9">
							<J3YesNo v-model="formData.outgoings.priority_bills"
								><template v-slot:heading
									>Are you in arrears with any of your
									priority bills?
								</template>
							</J3YesNo>
							<div class="tenspacer"></div>
							<p class="sidenote mt-3">
								Priority bills are those that carry the most
								serious consequences if they’re not paid. For
								example: council tax, TV licence, child
								maintenance, gas and electricity bills, Income
								tax, National insurance and VAT, mortgage or
								rent.
							</p>

							<J3Monthly
								v-model="
									formData.outgoings.outgoing_secured_loans
								"
								><template v-slot:heading
									>Other secured loans
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.outgoings
										.outgoing_building_insurance
								"
								><template v-slot:heading
									>Building & contents insurance
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.outgoings.outgoing_council_tax
								"
								><template v-slot:heading
									>Council tax
								</template>
							</J3Monthly>

							<J3Monthly v-model="formData.outgoings.outgoing_gas"
								><template v-slot:heading>Gas </template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.outgoings.outgoing_electricty"
								><template v-slot:heading>Electricty </template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.outgoings.outgoing_water"
								><template v-slot:heading>Water </template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.outgoings.outgoing_other_util"
								><template v-slot:heading
									>Other utilities - (coal, oil, other gas)
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.outgoings.outgoing_tv_licence"
								><template v-slot:heading>TV licence </template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.outgoings.outgoing_netflix"
								><template v-slot:heading
									>Entertainment packages (Netflix or Sky)
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.outgoings.outgoing_internet"
								><template v-slot:heading
									>Internet cost
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.outgoings.outgoing_mobile"
								><template v-slot:heading
									>Mobile phone
								</template>
							</J3Monthly>
						</v-form>
						<div class="tenspacer"></div>
						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 10">
				<v-row>
					<v-col
						><InfoBox
							><template v-slot:heading
								>Then, your essentials. </template
							><template v-slot:content
								>Items you need, such as utilities, groceries,
								and basic necessities. Identifying these
								essentials is key to building a simple and
								effective budget.
							</template></InfoBox
						></v-col
					>
				</v-row>

				<v-row>
					<v-col>
						<v-form ref="form10">
							<J3Monthly
								v-model="
									formData.essentials.essentials_ground_rent
								"
								><template v-slot:heading
									>Ground rent, service charges
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.essentials.essentials_mortgage
								"
								><template v-slot:heading
									>Mortgage endowment or mortgage PPI
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.essentials.essentials_court_fines
								"
								><template v-slot:heading
									>Magistrates or sheriffs court fines
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.essentials.essentials_child_support
								"
								><template v-slot:heading
									>Maintenance or child support payments
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.essentials.essentials_hire_purchase
								"
								><template v-slot:heading
									>Hire purchase or conditional payments
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.essentials.essentials_child_care
								"
								><template v-slot:heading
									>Childcare costs
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.essentials.essentials_adult_care
								"
								><template v-slot:heading
									>Adult care costs
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.essentials.essentials_pension"
								><template v-slot:heading
									>Pension contribution
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.essentials
										.essentials_life_insurance
								"
								><template v-slot:heading
									>Life insurance
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.essentials.essentials_debt"
								><template v-slot:heading
									>Debt repayments that you don’t want to
									include
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.essentials
										.essentials_public_transport
								"
								><template v-slot:heading
									>Public transport
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="formData.essentials.essentials_taxi"
								><template v-slot:heading
									>Other (Taxis)
								</template>
							</J3Monthly>
						</v-form>
						<div class="tenspacer"></div>
						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 11">
				<v-row>
					<v-col
						><InfoBox
							><template v-slot:heading>Housekeeping. </template
							><template v-slot:content
								>Items that covers daily expenses for
								maintaining your household, like cleaning
								supplies, minor repairs,
							</template></InfoBox
						></v-col
					>
				</v-row>
				<v-row>
					<v-col>
						<v-form ref="form11">
							<J3Monthly
								v-model="
									formData.housekeeping.housekeeping_food
								"
								><template v-slot:heading
									>Food, cleaning & toiletries
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.housekeeping.housekeeping_newspaper
								"
								><template v-slot:heading
									>Newspaper & magazines
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.housekeeping.housekeeping_laundry
								"
								><template v-slot:heading
									>Laundry & dry cleaning
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.housekeeping.housekeeping_clothing
								"
								><template v-slot:heading
									>Clothing & footwear
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.housekeeping.housekeeping_nappies
								"
								><template v-slot:heading
									>Nappies & baby items
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.housekeeping.housekeeping_pets
								"
								><template v-slot:heading>Pets </template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.housekeeping.housekeeping_alcohol
								"
								><template v-slot:heading
									>Smoking & alcohol
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.housekeeping
										.housekeeping_emergency_saving
								"
								><template v-slot:heading
									>Emergency savings
								</template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.housekeeping.housekeeping_other
								"
								><template v-slot:heading
									>Other household outgoings
								</template>
							</J3Monthly>
						</v-form>
						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 12">
				<v-row>
					<v-col
						><InfoBox
							><template v-slot:heading
								>The rest of your expenditures. </template
							><template v-slot:content
								>Creating a budget is the most effective way to
								regain control of your finances. Our budget tool
								also helps identify potential areas for savings.
							</template></InfoBox
						></v-col
					>
				</v-row>
				<v-row>
					<v-col>
						<v-form ref="form12">
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_dental
								"
								><template v-slot:heading
									>Dental & opticians
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_medicines
								"
								><template v-slot:heading>Medicines </template>
							</J3Monthly>

							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_schoolmeal
								"
								><template v-slot:heading
									>School meals
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_workmeal
								"
								><template v-slot:heading>Work meals </template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_pocketmoney
								"
								><template v-slot:heading
									>Pocket money
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_schooltrip
								"
								><template v-slot:heading
									>School trips
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_charity
								"
								><template v-slot:heading
									>Church & charity
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_boiler_insurance
								"
								><template v-slot:heading
									>Boiler & appliance insurance
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_houserepair
								"
								><template v-slot:heading
									>Repairs & house maintenance
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_hairdresser
								"
								><template v-slot:heading
									>Trips to the hairdresser
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures.expenditures_tv
								"
								><template v-slot:heading
									>TV, & other appliances rental
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_lottery
								"
								><template v-slot:heading>Lottery </template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_hobbies
								"
								><template v-slot:heading
									>Hobbies or sport
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_gift
								"
								><template v-slot:heading>Gifts </template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_pet_insurance
								"
								><template v-slot:heading
									>Pet insurance
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_bank_charges
								"
								><template v-slot:heading
									>Bank charges
								</template>
							</J3Monthly>
							<J3Monthly
								v-model="
									formData.other_expenditures
										.expenditures_others
								"
								><template v-slot:heading
									>Other (Holidays)
								</template>
							</J3Monthly>
						</v-form>
						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 13">
				<v-row>
					<v-col
						><InfoBoxL ico="home"
							><template v-slot:heading
								>Finally, that provides all we need to know. </template
							><template v-slot:content
								>However, this additional information helps us
								to show you solutions that you’re eligible for
								specifically in your location. Then tap; Go to
								my solutions button.
							</template></InfoBoxL
						></v-col
					>
				</v-row>
				<v-row>
					<v-col>
						<v-form ref="form13">
							<p
								style="
									font: normal normal bold 14px/19px Nunito;
									letter-spacing: 0px;
									color: #525355;
								"
							>
								Where do you live?
							</p>

							<v-chip-group
								v-model="formData.personal.country"
								column
								mandatory
								active-class="isSelChip"
							>
								<v-chip filter color="#DADBDF" value="England">
									England
								</v-chip>
								<v-chip filter color="#DADBDF" value="Scotland">
									Scotland
								</v-chip>
								<v-chip filter color="#DADBDF" value="Wales">
									Wales
								</v-chip>
								<v-chip
									filter
									color="#DADBDF"
									value="Northern_Ireland"
								>
									Northern Ireland
								</v-chip>
							</v-chip-group>

							<p
								style="
									font: normal normal bold 14px/19px Nunito;
									letter-spacing: 0px;
									color: #525355;
								"
								class="mt-5"
							>
								How long have you lived there?
							</p>
							<v-chip-group
								v-model="formData.personal.lived_here"
								column
								mandatory
								active-class="isSelChip"
							>
								<v-chip
									filter
									color="#DADBDF"
									value="over_12months"
								>
									Over 12 Months
								</v-chip>
								<v-chip
									filter
									color="#DADBDF"
									value="6_12months"
								>
									6 to 12 Months
								</v-chip>
								<v-chip
									filter
									color="#DADBDF"
									value="less_than_6months"
								>
									Less than 6 Months
								</v-chip>
							</v-chip-group>

							<J3YesNo v-model="formData.personal.improve_finical"
								><template v-slot:heading
									>Are there any changes in the near future,
									that could impact your financial situation?
								</template>
							</J3YesNo>
						</v-form>
						<Pager @vc="checkRequired" />
					</v-col>
				</v-row>
			</v-container>
			<v-container class="pa-0" v-if="currentStep == 14">
				<v-row>
					<v-col>
						<v-img
							:src="
								require('@/assets/graphic_icon_ipad_green_check.png')
							"
							width="98px"
							top
							center
							contain
							style="margin: 20px auto"
						/>
						<ThreeLine
							><template v-slot:heading
								>Enquiry transferred </template
							><template v-slot:content
								><p>
									Your enquiry has been transferred to the J3
									Debt Help Booster team. We will call you
									from an 0161 number within 48 hours.
								</p>
								<p>
									Your personal reference number is:
									<br /><b>{{ api_response.id }}</b>
								</p>
								<p>
									You can now close this page, thank you for
									your time and we look forward to providing
									you with customised debt help solutions.
								</p>
								<Pager @vc="noCheckNext" /> </template
						></ThreeLine>
					</v-col>
				</v-row>
			</v-container>
			<v-container>
				<v-row>
					<v-col>
						<div class="footer-text-card">
							<a
								href="https://www.j3debt.co.uk/our-fees-explained/"
								target="_blank"
								class="j3linkfooter"
								>Our Fees Explained
							</a>
							|
							<a
								href="https://www.j3debt.co.uk/customer-charter/"
								target="_blank"
								class="j3linkfooter"
								>Customer Charter
							</a>
							|
							<a
								href="https://www.j3debt.co.uk/privacy-policy/"
								target="_blank"
								class="j3linkfooter"
								>Cookies &amp; Privacy Policy</a
							>
						</div>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import InfoBox from "../components/Core/InfoBox.vue";
import InfoBoxL from "../components/Core/InfoBoxL.vue";
import AddressInput from "../components/j3/Address.vue";
import J3YesNo from "../components/j3/J3YesNo.vue";
import J3MultiToggle from "../components/j3/J3MultiToggle.vue";
import Creditors from "../components/j3/Creditors.vue";
import J3Monthly from "../components/j3/J3Monthly.vue";
import Pager from "../components/j3/Pager.vue";
import ThreeLine from "../components/j3/ThreeLine.vue";
import J3Currency from "../components/j3/J3Currency.vue";

export default {
	name: "Welcome",
	components: {
		InfoBox,
		InfoBoxL,
		AddressInput,
		J3YesNo,
		J3MultiToggle,
		Creditors,
		J3Monthly,
		Pager,
		ThreeLine,
		J3Currency,
	},
	data() {
		return {
			isLoadingAPI: false,
			rawDate: "",
			date_of_birth: "",
			is_email_dirty: false,
			app_market: process.env.VUE_APP_MARKET,
			rules: {
				required: (value) => !!value || "Required.",
				//counter: (value) => value.length >= 3 || "Min 3 characters",
				counter: (value) =>
					(value && value.length >= 2) || "Min 2 characters",

				email: (value) => {
					const pattern =
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					return pattern.test(value) || "Invalid e-mail.";
				},
				isValidphone: (value) => {
					return (
						this.formData.mobileChecker.result.responseData.valid ||
						"Invalid Phone"
					);
				},
				isValidEmail: (value) => {
					return (
						this.formData.emailChecker.result.responseData.valid ||
						"Invalid Email"
					);
				},
				isOverEighteen: (value) => {
					return (
						Math.abs(
							new Date(
								Date.now() - new Date(value).getTime(),
							).getUTCFullYear() - 1970,
						) >= 18 || "You’ll need to be over 18"
					);
				},
			},
		};
	},

	computed: {
		...mapState([
			"firebase_token",
			"formData",
			"isInsideForm",
			"mobileChecker",
			"api_response",
			"utm_source",
			"utm_medium",
			"utm_campaign",
			"utm_term",
		]),
		currentStep() {
			return this.formData.currentStep;
		},
		realiseText() {
			return this.formatObject(this.formData);
		},
		showCheckMarkEmail() {
			if (this.formData.emailChecker.result) {
				return this.formData.emailChecker.result.responseData.valid;
			} else {
				return false;
			}
		},
		showCheckMarkMobile() {
			if (this.formData.mobileChecker.result) {
				return this.formData.mobileChecker.result.responseData.valid;
			} else {
				return false;
			}
		},
	},
	watch: {
		formData: {
			handler: "saveFormProgress",
			deep: true,
		},
	},
	methods: {
		...mapActions([
			"saveFormProgress",
			"loadFormProgress",
			"showLogo",
			"prevPage",
			"nextPage",
		]),

		checkMobileAPI() {
			this.isLoadingAPI = true;
			this.$store
				.dispatch("searchMobile", {
					payload: {
						operations: "function1",
						data: {
							mobile: this.formData.personal.mobile,
						},
					},
				})
				.then((response) => {
					//console.log(this.mobileChecker, "2");
					this.$refs.mobile.validate();
					this.isLoadingAPI = false;
				});
		},
		checkEmailAPI() {
			this.is_email_dirty = true;
			const pattern =
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if (pattern.test(this.formData.personal.email)) {
				this.isLoadingAPI = true;
				this.$store
					.dispatch("searchEmail", {
						payload: {
							operations: "function2",
							data: {
								email: this.formData.personal.email,
							},
						},
					})
					.then((response) => {
						//console.log(this.mobileChecker, "2");
						this.$refs.email.validate();
						this.isLoadingAPI = false;
					});
			}
		},
		formatObject(obj, depth = 0) {
			const indent = "  ".repeat(depth);
			const variableNameMapping = {
				currentStep: "currentStepNumber",
				toggleBTGroup: "isBTGroupToggled",
				lines: "addressDetails",
				countryCode: "Country Code",
				number: "Number",
				phoneUsageType: "Phone Usage Type",
				housenumber: "House Number",
				buildingnumber: "Building Number",
				postcode: "Postcode",
				city: "City",
				county: "County",
				year: "Year",
				creditors: "creditorDetails",
				creditors_name: "Creditor Name",
				balance: "Balance",
				include_in_assessment: "Include in Assessment",
				arrears: "Arrears",
				againgst_property: "Against Property",
				against_property: "Against Property",
				surname: "LastName",
				email: "Email",
				dob: "Date of Birth",
				mental: "Has Mental Condition",
				sharemental: "Shares Mental Condition",
				ownproperty: "Owns Property",
				howmany: "Dependents Count",
				towardsavehicle: "Saving for Vehicle",
				residential_state: "Residential State",
				title: "Title",
				country: "Country",
				lived_here: "Has Lived Here Over 12 Months",
				include_debt: "Includes Debt",
				arrears_with_debt: "Has Arrears With Debt",
				over_1000: "Has Over 1000 Debt",
				outstanding_property: "Has Outstanding Property",
				is_essential: "Is the asset Essential",
				any_benefits: "Receives Any Benefits",
				is_pension: "Has Pension",
				priority_bills: "Has Priority Bills",
				improve_finical: "Wants to Improve Financial Situation",
				mobile: "Mobile Number",
				formattedDate: "Formatted Registration Date",
				ccj: "Has CCJ",
				jobtitle: "Job Title",
				income_main: "Main Income",
				income_other: "Other Income",
				date_of_birth: "Date of Birth",
				reason: "Financial Difficulty Reason",
				employment: "Employment Status",
				ppl_depend_on_you_financially:
					"People Depend On You Financially",
				residential_status: "Residential Status",
				marital_status: "Marital Status",
				rent_mortgage_cost: "Rent or Mortgage Cost",
				owe: "Amount Owed",
				outstanding_balance_against_property:
					"Has Outstanding Balance Against Property",
				vehicle_age: "Vehicle Age",
				vehicle_value: "Vehicle Value",
				pay_for_vehicle: "Vehicle Payment Method",
				type_of_assets: "Type of Asset",
				assets_name: "Asset Name",
				assets_value: "Asset Value",
				outstanding_balance: "Outstanding Balance",
				income_aboard: "Income from Abroad",
				from_friends: "Income from Friends",
				student_loan: "Student Loan Details",
				outgoing_netflix: "Netflix Subscription",
				outgoing_internet: "Internet Expense",
				outgoing_mobile: "Mobile Expense",
				outgoing_secured_loans: "Secured Loans Expense",
				outgoing_building_insurance: "Building Insurance Expense",
				outgoing_council_tax: "Council Tax Expense",
				outgoing_gas: "Gas Expense",
				outgoing_electricity: "Electricity Expense",
				outgoing_water: "Water Expense",
				outgoing_other_util: "Other Utilities Expense",
				outgoing_tv_licence: "TV Licence Expense",
				essentials_debt: "Essential Debt Expense",
				essentials_public_transport: "Public Transport Expense",
				essentials_taxi: "Taxi Expense",
				essentials_mortgage: "Mortgage Expense",
				essentials_court_fines: "Court Fines Expense",
				essentials_child_support: "Child Support Expense",
				essentials_hire_purchase: "Hire Purchase Expense",
				essentials_child_care: "Child Care Expense",
				essentials_adult_care: "Adult Care Expense",
				essentials_pension: "Pension Expense",
				essentials_life_insurance: "Life Insurance Expense",
				essentials_ground_rent: "Ground Rent Expense",
				housekeeping_newspaper: "Newspaper Expense",
				housekeeping_laundry: "Laundry Expense",
				housekeeping_clothing: "Clothing Expense",
				housekeeping_nappies: "Nappies Expense",
				housekeeping_pets: "Pets Expense",
				housekeeping_alcohol: "Alcohol Expense",
				housekeeping_emergency_saving: "Emergency Savings Expense",
				housekeeping_other: "Other Housekeeping Expense",
				housekeeping_food: "Food Expense",
				expenditures_medicines: "Medicines Expense",
				expenditures_schoolmeal: "School Meal Expense",
				expenditures_workmeal: "Work Meal Expense",
				expenditures_pocketmoney: "Pocket Money Expense",
				expenditures_schooltrip: "School Trip Expense",
				expenditures_charity: "Charity Expense",
				expenditures_boiler_insurance: "Boiler Insurance Expense",
				expenditures_houserepair: "House Repair Expense",
				expenditures_dental: "Dental Expense",
				expenditures_hairdresser: "Hairdresser Expense",
				expenditures_tv: "TV Expense",
				expenditures_lottery: "Lottery Expense",
				expenditures_hobbies: "Hobbies Expense",
				expenditures_gift: "Gift Expense",
				expenditures_pet_insurance: "Pet Insurance Expense",
				expenditures_bank_charges: "Bank Charges Expense",
				expenditures_others: "Other Expenses",
				in_arrears_with_your_rent_or_mortgage:
					"Are you in arrears with your rent/mortgage?",
			};
			return Object.entries(obj)
				.map(([key, value]) => {
					const updatedKey = variableNameMapping[key] || key;
					if (typeof value === "object" && value !== null) {
						const nested = this.formatObject(value, depth + 1);
						return `${indent}${updatedKey}:\n${nested}`;
					} else {
						return `${indent}${updatedKey}: ${value}`;
					}
				})
				.join("\n");
		},
		formatDate() {
			let formattedValue = this.date_of_birth.replace(/\D/g, "");

			// Format as MM/DD/YYYY
			if (formattedValue.length > 2) {
				formattedValue =
					formattedValue.substring(0, 2) +
					"/" +
					formattedValue.substring(2);
			}
			if (formattedValue.length > 5) {
				formattedValue =
					formattedValue.substring(0, 5) +
					"/" +
					formattedValue.substring(5, 9);
			}

			// Update the model with the formatted value
			this.date_of_birth = formattedValue;
			// Remove non-numeric characters

			// const numericValue = this.rawDate.replace(/\D/g, "");

			// // Format the date as DD-MM-YYYY
			// const day = numericValue.substring(0, 2);
			// const month = numericValue.substring(2, 4);
			// const year = numericValue.substring(4, 8);

			// // Update the formattedDate
			// this.date_of_birth = `${day}-${month}-${year}`;
		},
		noCheckNext() {
			this.$store.dispatch("nextPage");
			window.scrollTo({
				top: 0,
				behavior: "smooth",
			});
		},
		submitPart1() {
			this.$store.dispatch("postData", {
				form1: this.formData,
				as_text: this.realiseText,
			});
		},
		checkRequired() {
			this.is_email_dirty = true;
			//console.log("start check");
			//const isFieldValid =this.$refs.form1.formData.surname.$refs.input.valid;
			if (!this.$refs[`form${this.formData.currentStep}`].validate()) {
				const invalidField = this.$refs[
					`form${this.formData.currentStep}`
				].$children.find((e) => !e.valid);
				if (invalidField)
					invalidField.$el.scrollIntoView({
						behavior: "smooth",
					});

				return;
			}

			//this.$router.push("/welcome");
			if (this.currentStep == 4 || this.currentStep == 13) {
				this.$store.dispatch("postData", {
					form1: this.formData,
					as_text: this.realiseText,
					utm_term: this.utm_term,
					utm_medium: this.utm_medium,
					utm_source: this.utm_source,
					utm_campaign: this.utm_campaign,
					market: this.formData.market,
					callback: () => {
						window.scrollTo({
							top: 0,
						});
						this.$store.dispatch("nextPage");
					},
				});
			} else {
				this.$store.dispatch("pageAppLoading", {
					callback: () => {
						window.scrollTo({
							top: 0,
						});
						this.$store.dispatch("nextPage");
					},
				});
			}
		},
		submitnative() {
			this.checkRequired();
		},
	},

	mounted() {
		this.$store.dispatch("showInsideForm");
	},
	created() {
		this.$store.dispatch("carbonCopy");
		this.$store.dispatch("loadFormProgress");
		this.$store.dispatch("showLogo");
	},
};
</script>
<style>
.v-input__slot {
}
.theme--light.v-btn--active::before {
	opacity: 1;
}
.theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
	opacity: 1;
}

.v-btn--active span.v-btn__content > span {
	color: white;
}
.v-text-field.v-text-field--enclosed .v-text-field__details {
}

.tenspacer {
	display: block;
	height: 15px;
}

.sidenote {
	font-size: 12px;
	letter-spacing: 0.36px;
	color: #606368;
}

.v-chip.isSelChip {
	background-color: #58b3dd !important;
	color: white;
}

.theme--light.v-card {
	color: #3c4043;
}
.theme--light.v-btn {
	color: #3c4043;
}

.v-text-field--outlined > .v-input__control > .v-input__slot {
	//min-height: 50px;
}
.v-text-field--outlined .v-label {
	//top: 14px;
}
.footer-text-card {
	font: normal normal normal 12px/16px Nunito;
	letter-spacing: 0px;
	color: #606368;
	text-align: center;
	margin-top: 60px;
}

a.j3link {
	color: #606368;
}
a.j3linkfooter {
	color: #606368;
	text-decoration: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
	display: none;
}
</style>
